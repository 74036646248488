import React from 'react'

function NavBar() {
  return (
    <div className='   w-full flex flex-row snap-always snap-center '>
      <img  style={{width:'3%', margin:'1.5%'}} src="https://i.imgur.com/LoGKbTk.png" />
      
    </div>
  )
}

export default NavBar